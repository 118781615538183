<template>
  <div class="name">
    <van-nav-bar :title="$t('setname.title')" style="color:#735db2" >
      <template #left>
        <van-icon name="arrow-left" color="#735db2" @click="back()"/>
      </template>
      <template #right>
        <span class="nav-right" style="color:#735db2" @click="save()">{{$t('setname.save')}}</span>
      </template>
    </van-nav-bar>
    <van-cell-group>
      <van-field v-model="name" :readonly="userInfo.name!=null" :label="$t('setname.xingming')" :placeholder="$t('setname.xmpls')" />
    </van-cell-group>
    <!-- <p style="padding: 2rem 2rem;">{{$t('setname.tips')}}</p> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      name:"",
      userInfo:{}
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    save(){
      if(this.userInfo.name){
        this.$toast(this.$t('setname.notreset'));
        return true;
      }
      if(this.name === "" || this.name === null || this.name === undefined){
        this.$toast.fail(this.$t('setname.plsentername'));
        return false;
      }
      this.$http({
        method: 'get',
        data:{name:this.name},
        url: 'user_set_name'
      }).then(res=>{
        if(res.code === 200){
          this.getUserInfo();
          this.name = this.userInfo.name;
          this.$toast(res.msg);
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
			console.log(res.data.name)
          this.userInfo = res.data;
          this.name = res.data.name;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserInfo();
    }
  }
};
</script>

<style  scoped>
:export {
    gbcolor: linear-gradient(30deg,#7d76ef,#d63f8c)
}
.van-nav-bar {
	position: relative;
    z-index: 999;
	height:3rem ;
	color: #735db2;
	line-height: 3rem;
    background: none
}
.van-nav-bar__content{
	height:3rem;
}
.name {
    width: 100%;
    height: 100%;
    background: #f0f0f0;
    background: linear-gradient(180deg,#fff,#fefae9)
}

.name .van-hairline--bottom:after {
    border-bottom-width: 0
}

.name .van-cell {
    padding: 16px 20px;
    background: none;
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    padding: 0.5rem 0.6rem;
    overflow: hidden;
    color: #323233;
    font-size: 1rem;
    line-height: 2rem;
}
.name .van-cell .van-cell__title {
    display: flex;
    justify-content: flex-start;
    align-items: center
}

.name .van-cell .van-cell__title img {
    width: 20px;
    margin-right: 6px
}

.name .van-cell .van-cell__value {
    color: #735db2
}

.name .van-nav-bar {
    background: none
}

.name .van-nav-bar .van-icon,.name .van-nav-bar .van-nav-bar__title {
    color: #412188
}

.name>p {
    color: #dc2037;
    font-size: .8rem;
    padding: 1rem 0
}

</style>
