<template>
<div class="info">
	<div class="van-nav-bar van-hairline--bottom">
		<div class="van-nav-bar__content">
			<div class="van-nav-bar__left van-haptics-feedback"  @click="back()">
				<i class="van-badge__wrapper van-icon van-icon-arrow-left van-nav-bar__arrow"></i>
			</div>
			<div class="van-nav-bar__title van-ellipsis">
				{{$t('mine.infomation')}}
			</div>
		</div>
	</div>
	<van-cell-group>
		<van-cell :title="$t('infomation.username')" :value="userInfo.username" is-link />
		<van-cell :title="$t('infomation.name')" :value="userInfo.name" is-link  @click="toSetName()" />
		<van-cell :title="$t('infomation.bankcard')" :value="$t('common.disable')" is-link  @click="toSetBank()" />
		<van-cell :title="$t('infomation.phone')" :value="userInfo.phone" is-link  @click="toSetSex()"/>
	</van-cell-group>
</div>
</template>

<script>
export default {
  data() {
    return {
      isActive:false,
      show:false,
      isBank:false,
      userInfo:{}
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    toSetName(){
      this.$router.push({path:'/Setname'});
    },
    toSetBank(){
      this.$router.push({path:'/Setbank'});
    },
    toSetSex(){
      this.$router.push({path:'/Setsex'});
    },
    openHerderImg(){
      this.show = true;
    },
    select_header_img(url){
        this.isActive = url;
    },
    check(){
      this.$http({
        method: 'post',
        data:{header_img:this.isActive},
        url: 'user_header_img'
      }).then(res=>{
        if(res.code === 200){
          this.getUserInfo();
          this.$toast(res.msg);
          this.show = false;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
          this.userInfo = res.data;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    getUserBankInfo(){
      this.$http({
        method: 'get',
        url: 'user_get_bank'
      }).then(res=>{
        if(res.code === 200){
          if(res.data.is_bank){
            this.isBank = true;
          }else {
            this.isBank = false;
          }
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserInfo();
      this.getUserBankInfo();
    }
  }
};
</script>

<style lang='less' scoped>
:export {
    gbcolor: linear-gradient(30deg,#7d76ef,#d63f8c)
}
.van-nav-bar__title {
    max-width: 60%;
    margin: 0 auto;
    color: #4141d8;
}
.van-nav-bar {
	position: relative;
    z-index: 999;
	height:3rem ;
	line-height: 3rem;
    background: none
}
.info {
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg,#fff,#fefae9)
}

.info .van-hairline--bottom:after {
    border-bottom-width: 0
}

.info .van-cell {
    padding: 1.5rem 1.5rem;
    background: none
}

.info .van-cell .van-cell__title {
    display: flex;
    justify-content: flex-start;
    align-items: center
}

.info .van-cell .van-cell__title img {
    width: 1.5rem;
    margin-right: 6px
}

.info .van-cell .van-cell__value {
    color: #4141d8;
	line-height: 1.2rem;
	height: 1.2rem;
}

.info .van-nav-bar {
    background: none
}

.van-icon {
    font-size: 1rem;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.info .van-nav-bar .van-icon,.info .van-nav-bar__title {
    color: #412188
}

</style>
