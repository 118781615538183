export default {
	Language: 'English',
	system: {
		homePage: {
			title1: 'title1',
			title2: 'title2',
			description1: 'Last 7 days',
			description2: 'Nearly six months',
		}
	},
	login:{
		register:'Register',
		usernamePls:'Please enter your username',
		passwordPls:'Please enter password',
		toRegister:'Dont you have an account? Register as a member now',
		doLogin:'Login',	  
	},
	register:{
		repasswordPls:'Please enter the withdrawal password',  
		codePls:'Please enter the invitation code',  
		register:'Register',
		xieyi:'Agree to the Terms of Use',
		xieyiPls:'Please select the account opening agreement below!',
	},
	mine:{
		xyfs:'Credit score',
		ljjf:'Accumulated points',
		recharge:'Recharge',
		withdraw:'Withdraw',
		money:'Having a point',
		infomation:'essential information',
		moneylog:'Points History',
		withdrawrecord:'Withdrawal details',
		gamerecord:'Gift Record',
		notice:'Announcement Matters',
		mymove:'Watch history',
		loginpwd:'Login password',
		paypwd:'Use password',
		loginout:'cancellation',
		paypwdhasseted:'cancellation',
		setbank:'cancellation',
		lorr:'Login/Register',
	},
	infomation:{
		username:'Member ID',
		name:'full name',
		bankcard:'Withdrawal account',
		phone:'phone number',
		recharge:'Recharge',
		amount:'Having a point',
		toserve:'Please contact customer service',
		kfms:'If you want offline charging, please consult customer service. The recharge amount of some settlement channels will be randomly recharged to one decimal place. Please be sure to confirm during settlement',
	},
	withdraw:{
		title:'Withdrawal',
		log:'Withdrawal Record',
		method:'Withdrawal Method',
		avmoney:'Withdrawable balance',
		limit:'Withdrawal limit',
		submit:'Withdrawal',
		xiane:'Single transaction limit',
		numbers:'Withdrawal frequency',
		time:'arrival time',
		zuidi:'Least',
		zuigao:'Highest',
		numsmax:'Maximum daily cash withdrawals',
		ci:'times',
		timedetail:'The general remittance time is about 5 minutes, and the fastest remittance can be made within 2 minutes.',
		bank:'Bank Card',
		status1:'Pending Review',
		status2:'Success',
		status3:'Failed',
	},
	moneylog:{
		title:'Fund Details',
		type1:'Change',
		type2:'Bonus',
		type3:'Task',
		type4:'Bonus',
		type5:'Withdrawal',
		type6:'Withdrawal failed',
	},
	setting:{
		loginpwdtitle:'Change login password',
		oldpwd:'Please enter old password',
		newpwd:'Please enter a new password',
		renewpwd:'Please re-enter the new password',
		change:'change',
		plseall:'Please fill out the complete form!',
		pswwrong:'Password input does not match twice',
		paypwdtitle:'Set fund password',
		paypwd:'Please enter the fund password.',
		repaypwd:'Please re-enter the fund password.',
	},
	home:{
		gonggao:'Enjoy the ultimate luxury experience',
		qgkf:'National',
		hotlist:'Popularity Recommendation List',
		hotgame:'Task Recommendations',
		gaoji:'Advanced',
		smrz:'On site certification',
		videorz:'My own video authentication',
		star:'Rating',
		sg:'Height',
		xw:'Chest circumference',
		diqu:'Serviceable Area',
		apply:'Apply for a date',
		ssxz:'Real time status',
		joinvip:'Joined as a member',
	},
	video:{
		title:'Image Gallery',
		nums:'views',
		bofangliang:'views',
		hotlist:'Popular Recommendations',
		plsrefisrt:'Please recharge and watch the video',
	},
	choose:{
		title:'The best night',
		nav:'Girls',
		apply:'Match application',
		jianjie:'Girls profile',
		move:'Is it movable',
		click:'Click on authentication',
		detail:'detailed information',
		videodetail:'real-time video ',
	},
	common:{
		refresh:'Refresh successful',
		noallow:'Function disabled!',
		disable:'Disable',
		all:'All',
		nomore:'Not anymore.',
		nodata:'No data available',
		submit:'Submit',
		more:'View more data',
		pinglunnav:'Comment',
		pingluntitle:'Comment',
		pldtitle:'Comment Details',
		minenav:'Account',
		homenav:'Home',
		addbank:'Add Bank Card',
		setbank:'Withdrawal Account',
	},
	game:{
		expect:'standard-bearer',
		jieguo:'data',
		da:'Big',
		xiao:'Small',
		dan:'Single',
		shuang:'Double',
		大:'Big',
		小:'Small',
		单:'Single',
		双:'Double',
		order:'Work instruction manual',
		clear:'Clear order',
		submit:'Confirm submission',
		kjcc:'Successful winning, hobby:',
		lxgly:'Please contact your administrator.',
		plsmoney:'Please fill in the amount!',
		plsnum:'Please select a number!',
		moneynote:'Insufficient balance, please contact customer service to recharge!',
		lefttime:'Remaining time',
		lunci:'Round by round',
	},
	kefu:{
		title:'Online Service',
		lianxi:'Contact',
		content:'We are dedicated to serving you 24/7',
	},
	setname:{
		title:'Change Real Name',
		xingming:'Name',
		tips:'For the security of your account, the actual name must match the name of the bound bank card',
		xmpls:'Please enter the actual name.',
		save:'Save',
		notreset:'Do not repeat the settings!',
		plsentername:'Please enter a name!',
	},
};