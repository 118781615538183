<template>
	<div class="index">
		<van-nav-bar title="" left-text="" @click-left="toService" left-arrow fixed>
		  <template #title>
		    <div class="van-image" style="width: 90px;">
		    	<img src="img/logo.png" class="van-image__img">
			</div>
		  </template>
		  <template #left>
		    <van-icon name="chat-o" size="26" />
		  </template>
		</van-nav-bar>

		<div class="banner banners" style="margin-top: 5px;height: 10rem;" >
			<swiper class="swiper" :options="bannerSwiperOption">
			  <swiper-slide v-for="(v,key) in banners" :key="key">
					<a>
						<img class="banner_img"  :src="v.url" />
					</a>			      
			  </swiper-slide>
			</swiper>
			
		</div>
		<div class="item">
			<div class="title">
				<span class="animate__animated animate__fadeIn">
					HOT
					<i style="font-size: 14px;">
						{{$t('home.gonggao')}}
					</i>
				</span>
			</div>
			<div class="girl">
				<div>
					<ul class="gaoduan">
						<li v-for="(v,key) in osList" :key="key" @click="profile(v.id)" >
							<img :src="v.image" alt="">
							<p>
								하율
							</p>
							<span>
								<i>
									{{$t('home.qgkf')}}
								</i>
							</span>
						</li>
					</ul>
					<ul class="gaoduan">
						<li v-for="(v,key) in jsList" :key="key" @click="profile(v.id)">
							<img :src="v.image" alt="">
							<p>
								민설
							</p>
							<span>
								<i>
									{{$t('home.qgkf')}}
								</i>
							</span>
						</li>
						
					</ul>
				</div>
				<a @click="$router.push({path:'/Choose'});" >
					{{$t('common.more')}}
				</a>
			</div>
		</div>
		<div class="item">
			<div class="title">
				<span class="animate__animated animate__fadeIn">
					{{$t('home.hotgame')}}
				</span>
			</div>
			<ul class="list">
				<li  @click="toLottery(v.key,v.id)" v-for="(v,key) in gameitem" :key="key">
					<div class="image">
						<img class="animate__animated animate__flipInX" alt=""  :src="v.ico"></div>
					<p>
						{{v.name}}
					</p>
				</li>
				
			</ul>
		</div>
		<div class="item">
			<div class="title" style="padding-bottom: 10px;">
				<span class="animate__animated animate__fadeIn">
					{{$t('home.hotlist')}}
				</span>
			</div>
			<div class="tj">
				<ul>
					<li v-for="(v,key) in movielist_1" :key="key"  >
						<div class="left">
							<div style="display: flex; flex-direction: column;">
								<img :src="v.image" alt="">
								<p style="margin-top: 6px;">
									{{v.class_name}}
								</p>
							</div>
							<span>
								<i>
									{{$t('home.gaoji')}}
								</i>
							</span>
						</div>
						<div class="right">
							<div class="title">
								<span>
									<span class="van-badge__wrapper van-icon" style="font-size: 13px;">
										<img class="van-icon__image" src="/img/vipu.png"></span>
									{{$t('home.smrz')}}
								</span>
								<span>
									<span class="van-badge__wrapper van-icon" style="font-size: 13px;">
										<img class="van-icon__image" src="/img/vipu.png"></span>
									{{$t('home.videorz')}}
								</span>
							</div>
							<div class="tips">
								<span v-for="(val,index) in v.tags_arr" :key="index"  >
									{{val}}
								</span>

							</div>
							<div class="sm">
								<p class="sf">
									{{$t('home.star')}}：
									<span v-for="(numa,indexs) in v.star" :key="indexs" ></span>

								</p>
								<div>
									{{$t('home.sg')}}: {{v.sg}}cm {{$t('home.xw')}}：{{v.sw}}{{$t('home.diqu')}}：{{v.class_name}}
								</div>
							</div>
							<div class="btn"  @click="profile(v.id)">
								{{$t('home.apply')}}
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
		<div class="gundong" data-v-2c0cea1a="">
			<p data-v-2c0cea1a="">
				{{$t('home.ssxz')}} 
			</p>
			<swiper class="swipers" :options="vipSwiperOption">
			  <swiper-slide v-for="(v,key) in vip_list" :key="key" >
					{{v}} {{$t('home.joinvip')}} 
			  </swiper-slide>
			</swiper>
			
		</div>
	</div>
</template>

<script>
export default {
  data() {
    return {
      notice: "获取中......",
      banners: [],
      basicData:[],
      gameitem: [{},{},{},{}],
      movielist_0: [{},{},{},{}],
      movielist_1: [{},{},{},{},{},{},{},{}],
      vip_list: [],
      isLoading: false,
      movielistSwiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 0,
        slidesPerGroup : 1,
      },
      bannerSwiperOption: {
        loop: true,
        speed:1000,
        autoplay:true
      },
	  vipSwiperOption: {
		direction: 'vertical',
		slidesPerView:7,
	    loop: true,
	    speed:1000,
	    autoplay:true
	  }
    };
  },
  computed: {
      osList() {
        return this.movielist_0.filter((item, index) => index % 2 === 0);
      },
	  jsList() {
	    return this.movielist_0.filter((item, index) => index % 2 === 1);
	  },
    },
  methods:{
    gotoMenu(router){
      this.$router.replace(router)
    },
    toLottery(key,id){
      if(!localStorage.getItem('token')){
        this.$router.push({path:'/Login'})
      }else {
        this.$router.push({path:'/Lottery?key='+key+"&id="+id})
      }

    },
	profile(id) {
		this.$router.push({ path: '/profile?id=' + id  });
	},
	toService(){
	  if(this.$store.getters.getBaseInfo.iskefu == 1){
	    this.$router.push("ServiceOnline");
	  }else {
	    this.$toast(this.$t('common.refresh'));
	  }
	},
    toPlayVideo(id){
      if(!localStorage.getItem('token')){
        this.$router.push({path:'/Login'})
      }else {
        this.$router.push({path:'/PlayVideo?id='+id})
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.getBasicConfig();
        this.isLoading = false;
        this.$toast(this.$t('common.refresh'));
      }, 500);
    },
    getBasicConfig(){
      this.$http({
        method: 'get',
        url: 'sys_config'
      }).then(res=>{
        this.basicData = res.data;
		
		this.vip_list = this.basicData.vip_list;
        this.getNotice(this.basicData);//获取公告
        this.getBanner(this.basicData);//获取banner
        this.getGameItem();//获取首页游戏列表
        this.getMovieList_0(this.basicData);//获取首页视频0
        this.getMovieList_1(this.basicData);//获取首页视频1
      })

    },
    getNotice(data){
      this.notice = data.notice;
    },
    getGameItem(){
      this.$http({
        method: 'get',
        url: 'lottery_hot'
      }).then(res=>{
		  console.log(res.data);
        this.gameitem = res.data
      })
    },
    getMovieList_0(data){
      this.movielist_0 = data.movielist_0
    },
    getMovieList_1(data){
      this.movielist_1 = data.movielist_1
    },
    getBanner(data){
      this.banners = data.banners;
    }
  },
  mounted () {

  },
  created() {
    this.getBasicConfig();
  }
}

</script>

<style lang='less' scoped>
@import "../../assets/css/basenew.css";
.van-nav-bar {
    z-index: 999;
    position: absolute;
    background: url(/img/header.svg) no-repeat 50%/100%;
}
</style>
