<template>
<div class="recharge">
	<van-nav-bar :title="$t('infomation.recharge')" left-text="" @click-left="onClickLeft" left-arrow></van-nav-bar>

	<div class="top">
		<div>
			<van-image round width="4.2rem" height="4.2rem" :src="this.userInfo.header_img" >
				<template v-slot:loading>
				    <van-loading type="spinner"/>
				</template>
			</van-image>
			<div class="info">
				<p>
					{{this.userInfo.username}}
				</p>
				<p>
					{{$t('infomation.amount')}}：{{this.userInfo.money}} KRW
				</p>
			</div>
		</div>
	</div>
	<div class="van-tabs van-tabs--line">
		<div class="van-tabs__wrap">
			<div role="tablist" class="van-tabs__nav van-tabs__nav--line" aria-orientation="horizontal"
			style="border-color: transparent;">
				<!---->
				<!---->
				<!----></div>
		</div>
		<!---->
		<div class="van-tabs__content"></div>
	</div>
	<div class="kfsm">
		<img src="/img/kf.png" alt="">
		<p>
			{{$t('infomation.kfms')}}
		</p>
	</div>
	<div class="kf">
		<van-button type="success" round block size="large" @click="toService">{{$t('infomation.toserve')}}</van-button>
	</div>
	<!---->
	<!---->
	<!----></div>
</template>

<script>
export default {
  data() {
    return {
      userInfo:{
      },
      menu_top:40,
      isLoading: false,
    };
  },
  methods: {
    refresh(){
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
        if(localStorage.getItem('token')){
          this.$toast(this.$t('common.refresh'));
        }else{
          this.$router.push({path:'/Login'})
        }
      }, 500);
    },


    onRefresh() {
      setTimeout(() => {
        this.isLoading = false;
        if(localStorage.getItem('token')){
            this.getUserInfo();
            this.$toast(this.$t('common.refresh'));
        }else{
            this.$router.push({path:'/Login'})
        }
      }, 500);
    },
	onClickLeft(){
		this.$router.push("Mine");
	},

    toService(){
      if(this.$store.getters.getBaseInfo.iskefu == 1){
        this.$router.push("ServiceOnline");
      }else {
        this.$toast.fail(this.$t('common.noallow'));
      }
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
            this.userInfo = res.data;
            this.menu_top = 15;
            if(this.userInfo.status !== 1){
              this.$toast("账号下线");
              localStorage.clear()
              this.$router.push({path:'/Login'})
            }
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
  },
  created() {
    if(localStorage.getItem('token')){
        this.getUserInfo();
    }else {
      this.userInfo.username = "登录/注册";
      this.userInfo.ip = "登录可享受更多服务！";
      this.userInfo.header_img = "img/mine/avatar.png";
    }
  }
};
</script>

<style>
:export {
    gbcolor: linear-gradient(30deg,#7d76ef,#d63f8c)
}

.van-hairline--bottom:after {
    border-bottom-width: 0
}

.van-nav-bar {
    background: none;
    position: absolute;
    width: 100%
}

.foot {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    width: 100%;
    padding-left: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center
}

.foot p {
    font-size: .8rem
}

.foot p span {
    color: #f65567;
    font-size: 1.13333rem
}

.foot a {
    padding: .66667rem;
    background: linear-gradient(90deg,#f560cd,#4f2b8a);
    color: #fff
}

.recharge {
    width: 100%;
    height: 100%;
    background: #fff
}

.recharge .van-popup {
    background: linear-gradient(30deg,#7d76ef,#d63f8c);
    padding-top: 50px;
    overflow: hidden
}

.recharge .van-popup>p {
    position: absolute;
    top: 14px;
    left: 0;
    right: 0;
    margin: auto;
    font-size: 16px;
    color: #fff
}

.recharge .van-popup iframe {
    width: 100%;
    height: 100%;
    background: #fff;
    border: none
}

.recharge .van-tab__panel {
    background: #fff;
    border-top: 5px solid #f2f2f5
}

.recharge .van-tab__panel .title {
    padding: 10px 5%;
    text-align: left;
    font-size: 14px;
    font-weight: 600
}

.recharge .van-tab__panel nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 5%
}

.recharge .van-tab__panel nav a {
    width: 48%;
    height: 50px;
    border: 1px solid #e4e4e4;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    color: #999;
    font-size: 13px;
    font-weight: 600;
    margin: 6px 0;
    position: relative;
    overflow: hidden
}

.recharge .van-tab__panel nav a i {
    display: none
}

.recharge .van-tab__panel nav a.active {
    border-color: #f65567;
    color: #f65567
}

.recharge .van-tab__panel nav a.active span {
    color: #f65567
}

.recharge .van-tab__panel nav a span {
    font-weight: 600;
    font-size: 18px;
    color: #999;
    margin-right: 5px
}

.recharge .top {
    width: 100%;
    background: linear-gradient(41deg,#7d76ef,#e3549d 90%);
    padding: 4rem 5% 1.33333rem
}

.recharge .top .van-image {
    border: 4px solid #fff
}

.recharge .top>div {
    display: flex;
    justify-content: flex-start;
    align-items: center
}

.recharge .top>div .info {
    text-align: left;
    color: #fff;
    padding-left: 1rem
}

.recharge .top>div .info>p {
    padding: .33333rem 0
}

.recharge .top>p {
    color: #fff;
    text-align: justify;
    padding: .66667rem 0
}

.recharge .kfsm {
    width: 94%;
    margin: 0 auto;
    display: flex;
    background: #f2f2f5;
    padding: 1.33333rem;
    border-radius: 6.66667rem
}

.recharge .kfsm p {
    text-align: justify;
    padding-left: .66667rem;
    font-size: .83333rem;
    color: #390f86
}

.recharge .kfsm img {
    width: 3rem
}

.recharge .kf {
    margin-top: 3.33333rem
}

.recharge .kf .van-button--block {
    width: 60%;
    margin: 0 auto;
	height: 2.8rem;
	font-size: 1rem;
	color:#fff;
    background: linear-gradient(30deg,#9b54ca,#e6557f);
    border: none
}

</style>