<template>
  <div class="name bl">
    <van-nav-bar title="휴대폰번호 변경" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" @click="back()"/>
      </template>
	  <template #right>
	    <span class="nav-right" style="color:#735db2" @click="save()">저장</span>
	  </template>
    </van-nav-bar>
	<van-cell-group>
	  <van-field  readonly label="기존 휴대전화번호" :value="userInfo.phone" />
	  <van-field v-model="phone"  label="새 휴대전화번호"  />
	</van-cell-group>
  </div>
</template>

<script>
export default {
  data() {
    return {
      phone:"",
      userInfo:{}
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    save(){
      this.$http({
        method: 'post',
        data:{phone:this.phone},
        url: 'user_set_sex'
      }).then(res=>{
        if(res.code === 200){
          this.getUserInfo();
		  this.phone = '';
          this.$toast(res.msg);
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
          this.userInfo = res.data;
          this.radio = res.data.sex;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserInfo();
    }
  }
};
</script>

<style lang='less' scoped>
.van-nav-bar {
	position: relative;
    z-index: 999;
	height:3rem ;
	color: #735db2;
	line-height: 3rem;
    background: none
}
.van-nav-bar__content{
	height:3rem;
}
.name {
    width: 100%;
    height: 100%;
    background: #f0f0f0;
    background: linear-gradient(180deg,#fff,#fefae9)
}

.name .van-hairline--bottom:after {
    border-bottom-width: 0
}

.name .van-cell {
    padding: 16px 20px;
    background: none;
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    padding: 0.5rem 0.6rem;
    overflow: hidden;
    color: #323233;
    font-size: 1rem;
    line-height: 2rem;
}
.name .van-cell .van-cell__title {
    display: flex;
    justify-content: flex-start;
    align-items: center
}

.name .van-cell .van-cell__title img {
    width: 20px;
    margin-right: 6px
}

.name .van-cell .van-cell__value {
    color: #735db2
}

.name .van-nav-bar {
    background: none
}

.name .van-nav-bar .van-icon,.name .van-nav-bar__title {
    color: #412188
}

.name>p {
    color: #dc2037;
    font-size: .8rem;
    padding: 1rem 0
}
</style>
