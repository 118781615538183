export default {
	Language: '日本語',
	system: {
		homePage: {
			title1: '标题1',
			title2: '标题2',
			description1: '近7日',
			description2: '近6个月',
		}
	},
	login:{
		register:'会員登録',
		usernamePls:'ユーザー名を入力してください',
		passwordPls:'パスワードを入力してください',
		toRegister:'アカウントはありませんか？「今から会員登録しよう」',
		doLogin:'ログイン',	  
	},
	register:{
		repasswordPls:'引き出しパスワードを入力してください',  
		codePls:'招待コードを入力してください',  
		register:'登録',
		xieyi:'利用規約に同意する',
		xieyiPls:'次の口座開設プロトコルを選択してください！',
	},
	mine:{
		xyfs:'信用スコア',
		ljjf:'積算積分',
		recharge:'チャージ',
		withdraw:'現金化',
		money:'利用可能残高',
		infomation:'基本情報',
		moneylog:'積分記録',
		withdrawrecord:'引き出し明細',
		gamerecord:'贈り物の記録',
		notice:'公告事項',
		mymove:'視聴記録',
		loginpwd:'ログインパスワード',
		paypwd:'パスワードを使用',
		loginout:'ログアウト',
		paypwdhasseted:'引き出しパスワードが設定されています。変更が必要な場合は、カスタマーセンターにお問い合わせください',
		setbank:'入金カードを設定してください！！',
		lorr:'ログイン/登録',
	},
	infomation:{
		username:'会員ID',
		name:'名前',
		bankcard:'引き出し口座',
		phone:'携帯電話番号',
		recharge:'チャージ',
		amount:'所有点',
		toserve:'カスタマーサービスに連絡してください',
		kfms:'オフラインで充電したい場合は、カスタマーサービスにお問い合わせください。一部の決済チャネルのチャージ金額は小数点1桁にランダムにチャージされますので、決済時に必ず確認してください',
	},
	withdraw:{
		title:'現金化',
		log:'出金記録',
		method:'引き出し方法',
		avmoney:'引き出し可能残高',
		limit:'引き出し限度',
		submit:'引き出し申請',
		xiane:'一筆書きの限度額',
		numbers:'引き出し回数',
		time:'入金時間',
		zuidi:'最小',
		zuigao:'最高',
		numsmax:'1日最大現金引き出し',
		ci:'次',
		timedetail:'一般的な送金時間は5分程度で、最速で2分以内に送金する。',
		bank:'銀行カード',
		status1:'レビュー待ち',
		status2:'成功',
		status3:'失敗',
	},
	moneylog:{
		title:'資金明細',
		type1:'変更',
		type2:'彩金',
		type3:'タスク',
		type4:'ボーナス',
		type5:'引き出し',
		type6:'引き出しに失敗した',
	},
	setting:{
		loginpwdtitle:'ログインパスワードの変更',
		oldpwd:'古いパスワードを入力してください',
		newpwd:'新しいパスワードを入力してください',
		renewpwd:'新しいパスワードを再入力してください',
		change:'変更',
		plseall:'完全な表に記入してください！',
		pswwrong:'2回のパスワード入力が一致しない',
		paypwdtitle:'資金パスワードの設定',
		paypwd:'資金パスワードを入力してください',
		repaypwd:'資金パスワードを再入力してください',
	},
	home:{
		gonggao:'究極のラグジュアリー体験を楽しむ',
		qgkf:'全国可能',
		hotlist:'人気おすすめリスト',
		hotgame:'タスクの推奨',
		gaoji:'高級',
		smrz:'実地認証',
		videorz:'本人ビデオ認証',
		star:'スコア',
		sg:'身長',
		xw:'バスト',
		diqu:'サービス可能領域',
		apply:'デートを申し込む',
		ssxz:'リアルタイムの現状',
		joinvip:'会員に加入しました',
	},
	video:{
		title:'映像館',
		nums:'再生量',
		bofangliang:'再生量',
		hotlist:'人気推薦',
		plsrefisrt:'チャージしてビデオを見てください！！',
	},
	choose:{
		title:'最高の夜',
		nav:'選妃',
		apply:'マッチング申請',
		jianjie:'美女プロフィール',
		move:'移動可能かどうか',
		click:'クリック認証',
		detail:'詳細',
		videodetail:'リアルタイムビデオ',
	},
	common:{
		refresh:'リフレッシュ成功',
		noallow:'機能が無効になっています！！',
		disable:'無効',
		all:'すべて',
		nomore:'これ以上はありません。',
		nodata:'データなし',
		submit:'コミット',
		more:'詳細を表示',
		pinglunnav:'コメント',
		pingluntitle:'コメント',
		pldtitle:'コメント詳細',
		minenav:'アカウント',
		homenav:'ホームページ',
		addbank:'入金カードの追加',
		setbank:'引き出し口座',
	},
	game:{
		expect:'期間番号',
		jieguo:'データ',
		da:'大',
		xiao:'小',
		dan:'単',
		shuang:'双',
		大:'大',
		小:'小',
		单:'単',
		双:'双',
		order:'タスクシート',
		clear:'注文を空にする',
		submit:'確定提出',
		kjcc:'受賞成功、期号:',
		lxgly:'管理者に連絡してください.',
		plsmoney:'金額を記入してください！',
		plsnum:'番号を選択してください！',
		moneynote:'残高が足りないので、カスタマーサービスに連絡してチャージしてください！',
		lefttime:'残り時間',
		lunci:'期号',
	},
	kefu:{
		title:'オンラインカスタマーサービス',
		lianxi:'連絡',
		content:'24時間365日対応',
	},
	setname:{
		title:'実名修正',
		xingming:'名前',
		tips:'アカウントのセキュリティのために、実際の名前はバインドされた銀行カード名と一致しなければなりません',
		xmpls:'実際の名前を入力してください',
		save:'保存',
		notreset:'繰り返し設定しないで！',
		plsentername:'名前を入力してください！',
	},
  
};
