<template>
  <div class="container page">
    <div class="header">
      <van-nav-bar :title="$t('withdraw.log')">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()"/>
        </template>
      </van-nav-bar>
    </div>
    <div class="main">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
          <van-empty v-if="list.length === 0" :description="$t('common.nodata')" />
		  <van-list  v-else
		      v-model="loading"
		      :finished="finished"
		      :immediate-check="false"
		      :finished-text="$t('common.nomore')"
		      @load="onLoad">
		  		  <van-cell-group>
		  		    <van-cell v-for="(item,i) in list" :key="i" :title="item.title" :value="statusExt[item.status]" :label="item.create_time" >
		  				<template #title>
		  					<div style="display: flex;">
		  						{{$t('withdraw.title')}}{{item.money}}KRW
		  					</div>
		  				</template>
						<template #default>
							<div style="display: flex;justify-content: space-between;">
								<span style="color: rgb(245, 105, 105);" @click="showD(item.desc);">알림</span>
								<span  v-if="item.status==1">{{statusExt[item.status]}}</span>
								<span style="color: rgb(54, 201, 137);" v-else-if="item.status==2">{{statusExt[item.status]}}</span>
								<span style="color: rgb(245, 105, 105);" v-else>{{statusExt[item.status]}}</span>
							</div>
						</template>
		  			</van-cell>
		  		  </van-cell-group>
		  </van-list>

          
      </van-pull-refresh>
    </div>
  </div>

</template>

<script>
import { Dialog } from 'vant';
export default {
  data() {
    return {
	  page:1,
	  isLoading: false,
	  count:0,
	  limit:10,
	  loading: false,
	  finished: false,
	  refreshing: false,
      list:[],
	  statusExt:['',this.$t('withdraw.status1'),this.$t('withdraw.status2'),this.$t('withdraw.status3')],
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    onRefresh() {
      setTimeout(() => {
        this.$toast(this.$t('common.refresh'));
        this.isLoading = false;
      }, 500);
    },
	showD(desc){
		Dialog.alert({
		  title: '알림',
		  message: desc,
		}).then(() => {
		  // on close
		});
	},
    getUserWithdrawList(){
      this.$http({
        method: 'get',
        url: 'user_get_withdraw_list'
      }).then(res=>{
        if(res.code === 200){
          this.list = res.data;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserWithdrawList();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
::v-deep .van-pull-refresh__track .van-pull-refresh__head *{
  color: #000000;
  font-size: 1rem;
}

::v-deep .van-loading__text {
  color: #aaa;
  font-size: 1rem;
}
.container .main{
  position: relative;
  overflow: auto;
  background-color: #f2f2f5;
  height: 100%;
  padding: 0 10px;
}

.item_list{
  padding: 15px 15px;
  margin: 30px 10px;
  background: #fff;
  border-radius: 10px;
  line-height: 60px;
}

.item_list .topInfo span{
  flex: 1;
  font-size: 35px;
  font-weight: 700;
  color: #ff253f;
}
.item_list .time span{
  flex: 1;
  font-size: 25px;
  font-weight: 500;
  color: #000;
}

.item_list .topInfo span:last-child{
  float: right;
}
.item_list .desc span{
  font-size: 25px;
  font-weight: 700;
  color: #9b9b9b;
}
.van-cell__label {
    margin-top: 0.533vw;
    color: #969799;
    font-size: 0.8rem;
    line-height: 1rem;
}
</style>
