<template>
<div class="withdraw">
	<van-nav-bar :title="$t('withdraw.title')" left-text="" @click-left="back()" left-arrow >
	  <template #right>
	    <span style="color: rgb(255, 255, 255);font-size: 1rem;"  @click="$router.push({path:'/WithdrawRecord'});">
	    	{{$t('withdraw.log')}}
	    </span>
	  </template>
	</van-nav-bar>
	<!---->
	<div class="top">
		<van-dropdown-menu >
		  <van-dropdown-item style="font-size: 2rem;" v-model="value1" :options="banklist" />
		</van-dropdown-menu>
		
		<p class="types">
			{{$t('withdraw.method')}}
		</p>
	</div>
	<div class="top center">
		<p>
			{{$t('withdraw.title')}} (KRW)
		</p>
		<div class="ipt">
			<p>
				KRW
			</p>
			<input v-model="withdraw_money" :placeholder="$t('withdraw.avmoney')+userInfo.money" type="number">
			<span  @click="allMoeny()">
				{{$t('common.all')}}
			</span>
		</div>
		<div class="sm">
			<div class="tips">
				<van-popover v-model="showPopover" trigger="click">
					<div class="popover-body" style="padding: 10px;">
<!-- 						<p>1.{{$t('withdraw.xiane')}}：{{$t('withdraw.zuidi')}}{{this.withdrawRole.min}}KRW，{{$t('withdraw.zuigao')}}{{this.withdrawRole.max}}KRW</p>
						<p>2.{{$t('withdraw.numbers')}}：{{$t('withdraw.numsmax')}}{{this.withdrawRole.num}}{{$t('withdraw.ci')}}</p>
						<p>3.{{$t('withdraw.time')}}：{{$t('withdraw.timedetail')}}</p> -->
						<p>1. 단일 거래 한도: 최소 100.00KRW, 최대 1000000.00KRW</p>
						<p>2. 일일 인출: 최대 10000000.00KRW</p>
						<p>3. 출금 횟수: 1일 최대 999회(수수료 없이 500회)</p>
						<p>4.평균 소요시간은 5분에서 2시간정도 소요되며 순차적으로 처리됩니다.</p>
					</div>
				  <template #reference @click="withdrawInfo()">
				    <van-icon name="question-o" size="1.3rem" />
				    {{$t('withdraw.limit')}}
				  </template>
				</van-popover>
			</div>
			<div class="syjf" >
				{{$t('withdraw.avmoney')}}：
				<span style="color: #d53dde;">
					{{ this.userInfo.money }} KRW
				</span>
			</div>
		</div>
	</div>
	<van-dialog
	    v-model="showDialog"
	    title="인출 비밀번호를 입력하다."
	    show-cancel-button
	    @confirm="onConfirm"
	    @cancel="showDialog = false"
	  >
	    <van-field
	      v-model="paypsd"
	      ref="inputField"
		  type="password"
	      label="암호"
	      placeholder="123456"
	    />
	  </van-dialog>
	<div class="btn">
		<a  @click="doWithdraw()">
			{{$t('withdraw.submit')}}
		</a>
	</div>
</div>
</template>

<script>
export default {
  data() {
    return {
		showDialog:false,
		paypsd:'',
      showPopover: false,
      withdraw_money:"",
      userInfo:{},
	  value1: 0,
	  banklist: [
		  { text: this.$t('withdraw.bank'), value: 0 }
		],
      withdrawRole:{}
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
          this.userInfo = res.data;
          this.name = res.data.name;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    getUserWithdrawRole(){
      this.$http({
        method: 'get',
        url: 'user_get_withdraw_role'
      }).then(res=>{
		  console.log(res)
        if(res.code === 200){
          this.withdrawRole = res.data.data;
		  this.banklist = this.banklist.concat(res.data.list);
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    allMoeny(){
      this.withdraw_money = this.userInfo.money;
    },
    doWithdraw(){
      if(this.withdraw_money <= 0){
        this.$toast("정확한 금액을 기입해 주십시오.");
        return false;
      }else if(this.value1 == 0){
		 this.$toast("현금인출 은행카드를 선택하세요");
		 return false; 
	  }else {
		 
        this.showDialog = true;
      }
    },
	onConfirm(){
		if(this.paypsd == ''){
		  this.$toast("결제 비밀번호를 입력하십시오.");
		  return false;
		}else {
			this.$http({
			  method: 'post',
			  data:{password:this.paypsd},
			  url: 'user_check_paypw'
			}).then(res=>{
			  if(res.code === 200){
			    this.dosubmit()	;
			  }else if(res.code ===401){
			    this.$toast(res.msg);
			  }
			})
			 
		  
		}
	},
	dosubmit(){
		this.$http({
		  method: 'post',
		  data:{money:this.withdraw_money,bankid:this.value1},
		  url: 'user_set_withdraw'
		}).then(res=>{
		  if(res.code === 200){
		    location. reload()
		    this.$toast(res.msg);
		    this.getUserInfo();
		    this.getUserWithdrawRole();
		  }else if(res.code ===401){
		    this.$toast(res.msg);
		  }
		})
	},
    withdrawInfo(){
      this.showPopover = true;
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserInfo();
      this.getUserWithdrawRole();
    }
  }
};
</script>

<style >
	/* @import "../../assets/css/basenew.css"; */
:export {
    gbcolor: linear-gradient(30deg,#7d76ef,#d63f8c)
}

.syjf {
    color: #2e0d67
}

.syjf span {
    color: #d53dde
}

.popover-body {
    font-size: .83333rem;
    width: 100%;
    color: #2e0d67
}

.popover-body p {
    text-align: justify;
    padding: .26667rem
}

.van-field {
    width: 50%;
    margin: 0 auto
}

.van-field .van-field__value {
    /* border: 1px solid #c5c5c5 */
}

.van-field .van-field__value input {
    /* text-align: center */
}

.withdraw {
    width: 100%;
    height: 100%;
    background: url(/img/jf.png) no-repeat top / 100%, #fff;
}
.withdraw .van-field {
    width: 100%;
    margin: 0 auto;
}
.withdraw .van-nav-bar {
	position: relative;
    background: none
}

.withdraw .van-hairline--bottom:after {
    border-bottom-width: 0
}

.withdraw .top {
    width: 90%;
    background: #fff;
    margin: .6rem auto;
    padding: 1rem 5%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: .33333rem;
    position: relative
}

.withdraw .top .van-dropdown-menu .van-cell__title {
    white-space: nowrap
}

.withdraw .top .types {
    position: absolute;
    right: 0
}

.van-dropdown-menu__bar {
    box-shadow: none;
    height: auto
}

.van-dropdown-menu__title {
    font-size: 1rem;
	line-height: 2rem;
}
.van-cell{
	font-size: 1rem;
	line-height: 1rem;
}
.van-cell__value i{
	font-size: 1rem;
}
.withdraw .top .van-dropdown-item {
    width: 90%;
    left: 0;
    right: 0;
    margin: auto
}
.van-dropdown-menu__title::after {
    position: absolute;
    top: 50%;
    right: -0.58rem;
    margin-top: -0.58rem;
    border: 0.32rem solid;
    border-color: transparent transparent #dcdee0 #dcdee0;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    opacity: .8;
    content: '';
}
.withdraw .top .van-icon {
    color: #cc2996
}

.withdraw .top.center {
    flex-direction: column;
    align-items: flex-start
}

.withdraw .top p {
    margin-right: .66667rem;
    color: #442b89
}

.withdraw .top>.ipt {
    display: flex;
    width: 100%;
    padding: 1rem 0 0;
    justify-content: space-between;
	border:none;
    align-items: center
}

.withdraw .top>.ipt input {
    border: none;
    flex: 1;
    padding: .66667rem 0
}

.withdraw .top>.ipt p {
    padding-right: .66667rem
}

.withdraw .top>.ipt span {
    padding-left: 1rem;
    color: #735fb1
}

.withdraw .top .sm {
    width: 100%;
    padding: .66667rem 0;
    border-top: 1px solid #f5f6f7
}

.withdraw .top .sm .tips {
    text-align: right;
    width: 100%;
    color: #979799;
    padding: 0;
    font-size: .76667rem
}

.withdraw .top .sm .tips .van-popover__wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #6c55ae
}

.withdraw .top .sm>div {
    width: 100%;
    text-align: left;
    padding-top: .66667rem
}

.withdraw .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .66667rem;
    margin-top: 3.33333rem
}

.withdraw .btn a {
    background: linear-gradient(90deg,#e9557f,#7e55e9);
    width: 100%;
    color: #fff;
    padding: .86667rem 0;
    border-radius: 3.66667rem;
    font-size: .86667rem
}

</style>
