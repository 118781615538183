<template>
<div class="jfdetail">
	<div class="van-nav-bar van-hairline--bottom routes">
		<div class="van-nav-bar__content">
			<div class="van-nav-bar__left van-haptics-feedback" @click="back()">
				<i class="van-badge__wrapper van-icon van-icon-arrow-left van-nav-bar__arrow"></i>
			</div>
			<div class="van-nav-bar__title van-ellipsis">
				{{$t('moneylog.title')}}
			</div>
		</div>
	</div>
	<van-pull-refresh v-model="isLoading" class="fixedStyle"  @refresh="onRefresh">
	  <van-list
	      v-model="loading"
	      :finished="finished"
	      :immediate-check="false"
	      :finished-text="$t('common.nomore')"
	      @load="onLoad">
		  <van-cell-group>
		    <van-cell v-for="(item,i) in tableList" :key="i" >
				<template #title>
					<div class="box">
						<div class="left">
							$
						</div>
						<div>
							<p>
								{{typeExt[item.type]}}
							</p>
							<span class="sp1">
								{{item.createtime}}
							</span>
						</div>
					</div>
					<span class="sp2" style="color: rgb(135, 135, 135); font-size: 12px;" v-if="item.type!=1 && item.type!=5 ">
						({{item.memo}})
					</span>
					<span class="sp2" style="color: rgb(135, 135, 135); font-size: 12px;" v-if="item.type==1">
						（충전）
					</span>
				</template>
				<template #default>
					<span style="color: rgb(60, 209, 151);" v-if="item.money>=0">
						+{{item.money}}
					</span>
					<span style="color: rgb(245, 105, 105);" v-else>
						{{item.money}}
					</span>
				</template>
			</van-cell>
		  </van-cell-group>
	  </van-list>
	  <van-empty :description="$t('common.nodata')" v-if="tableList.length === 0"/>
	</van-pull-refresh>
	
</div>
</template>

<script>
import { Toast } from 'vant';
export default {
  data () {
    return {
      page:1,
      isLoading: false,
      count:0,
	  limit:10,
      loading: false,
      finished: false,
      refreshing: false,
      tableList:[],
	  typeExt:['0',this.$t('moneylog.type1'),this.$t('moneylog.type2'),this.$t('moneylog.type3'),this.$t('moneylog.type4'),this.$t('moneylog.type5'),this.$t('moneylog.type6')],
    }
  },
  mounted () {
    this.getPinglunList()
    window.addEventListener("scroll", this.scrollTops, true);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollTops);
  },
  methods: {
	  back(){
	    return window.history.back();
	  },
	quickList(id){
	  if(!localStorage.getItem('token')){
	    this.$router.push({path:'/Login'})
	  }else {
	    this.$router.push({path:'/PinglunDetail?id='+id})
	  }
	
	},
    longPress() {
      document.querySelector('.fixedStyle').scrollTop = 0
    },
    scrollTops(){
      this.scrollTop1 = document.querySelector('.fixedStyle').scrollTop
    },

    getPinglunList(){
        this.$http({
          method: 'get',
          data:{page: this.page,limit: this.limit},
          url: 'money_log'
        }).then(res=>{
			console.log(res);
          this.tableList  = this.tableList.concat(res.data.data);
          this.count = res.data.total;
          this.page++;      
        })
    },
    onRefresh(){
      setTimeout(() => {
        this.finished = false;
        this.loading = true;
        this.onLoad();
        this.isLoading = false;
        Toast(this.$t('common.refresh'));
      }, 500);
    },
    onLoad() {
      //初始化列表
      this.getPinglunList()
	  let timer = setTimeout(() => {
	    if (this.refreshing) {
	      this.tableList = [];
	      this.refreshing = false;
	    }
	    this.loading = false;
	    if (this.tableList.length === this.count) {
	      this.finished = true;
	    }
	    this.finished && clearTimeout(timer);//清除计时器
	  }, 500);
    },
  },
}
</script>

<style>
:export {
    gbcolor: linear-gradient(30deg,#7d76ef,#d63f8c)
}
.van-nav-bar {
    z-index: 999;
	position: relative;
    background: url(/img/header.svg) no-repeat 50%/100%;
}
.van-nav-bar__content{
	height:3rem;
}
.routes {
    background: linear-gradient(20deg,#a253d0,#d63f8c);
}
.van-cell {
    width: 100%;
    padding: 0.9rem 0.6rem;
    overflow: hidden;
    color: #323233;
    line-height: 1.2rem;
    background-color: #fff;
}
.jfdetail {
    width: 100%;
    height: 100%;
    background: #f0f0f0
}

.jfdetail .van-cell {
    margin-bottom: 0.5rem
}

.jfdetail .van-cell:before {
    display: none
}

.jfdetail .van-cell-group {
    width: 100%;
    margin: 0.5rem auto;
    background: none
}

.jfdetail .van-cell__value {
    color: #f26060;
    font-size: 1rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-weight: 600;
    flex: auto
}

.jfdetail .van-cell__title {
    width: 80%;
    flex: auto;
    display: flex;
    flex-direction: column
}

.jfdetail .van-cell__title .box {
    display: flex;
    justify-content: flex-start;
    align-items: center
}

.jfdetail .van-cell__title .box .left {
    width: 2.5rem;
    height: 2.5rem;
    background: #f16290;
    margin-right: 10px;
    border-radius: 50%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem
}

.jfdetail .van-cell__title p {
    font-weight: 600
}

.jfdetail .van-cell__title .sp1 {
    font-size: 0.7rem
}

.jfdetail .van-cell__title .sp2 {
    color: #9c91c0!important
}
.van-list__error-text, .van-list__finished-text, .van-list__loading {
    color: #969799;
    font-size: 0.9rem;
    line-height: 1.5rem;
    text-align: center;
}
</style>