export default {
	Language: '中文',
	system: {
		homePage: {
			title1: '标题1',
			title2: '标题2',
			description1: '近7日',
			description2: '近6个月',
		}
	},
	login:{
		register:'注册会员',
		usernamePls:'请输入用户名',
		passwordPls:'请输入密码',
		toRegister:'没有账号吗？“现在注册会员吧',
		doLogin:'登录',	  
	},
	register:{
		repasswordPls:'请输入取款密码',  
		codePls:'请输入邀请代码',  
		register:'注册',
		xieyi:'同意使用条款',
		xieyiPls:'请选择下面的开户协议！',
	},
	mine:{
		xyfs:'信用分数',
		ljjf:'累计积分',
		recharge:'充值',
		withdraw:'提现',
		money:'可用余额',
		infomation:'基本信息',
		moneylog:'积分记录',
		withdrawrecord:'提款明细',
		gamerecord:'礼品记录',
		notice:'公告事项',
		mymove:'观看记录',
		loginpwd:'登录密码',
		paypwd:'使用密码',
		loginout:'注销',
		paypwdhasseted:'已设置取款密码。如果需要修改，请与客户中心联系',
		setbank:'请设置收款卡！',
		lorr:'登录/注册',
	},
	infomation:{
		username:'会员ID',
		name:'姓名',
		bankcard:'提款账户',
		phone:'手机号码',
		recharge:'充值',
		amount:'拥有点',
		toserve:'请联系客户服务',
		kfms:'想要线下充电，请向客服咨询。部分结算渠道的充值金额将随机充值至小数点1位，结算时请务必确认',
	},
	withdraw:{
		title:'提现',
		log:'取款记录',
		method:'取款方法',
		avmoney:'可提现余额',
		limit:'取款限度',
		submit:'提款申请',
		xiane:'单笔限额',
		numbers:'取款次数',
		time:'到账时间',
		zuidi:'最少',
		zuigao:'最高',
		numsmax:'每天最多提取现金',
		ci:'次',
		timedetail:'一般汇款时间为5分钟左右，最快在2分钟内汇款。',
		bank:'银行卡',
		status1:'待审核',
		status2:'成功',
		status3:'失败',
	},
	moneylog:{
		title:'资金明细',
		type1:'变更',
		type2:'彩金',
		type3:'任务',
		type4:'奖金',
		type5:'取款',
		type6:'取款失败',
	},
	setting:{
		loginpwdtitle:'更改登录密码',
		oldpwd:'请输入旧密码',
		newpwd:'请输入新密码',
		renewpwd:'请重新输入新密码',
		change:'更改',
		plseall:'请填写完整表格！',
		pswwrong:'两次密码输入不匹配',
		paypwdtitle:'设置资金密码',
		paypwd:'请输入资金密码',
		repaypwd:'请重新输入资金密码',
	},
	home:{
		gonggao:'享受终极奢华体验',
		qgkf:'全国可能',
		hotlist:'人气推荐列表',
		hotgame:'任务推荐',
		gaoji:'高级',
		smrz:'实地认证',
		videorz:'本人视频认证',
		star:'评分',
		sg:'身高',
		xw:'胸围',
		diqu:'可服务区域',
		apply:'申请约会',
		ssxz:'实时现状',
		joinvip:'加入了会员',
	},
	video:{
		title:'影像馆',
		nums:'播放量',
		bofangliang:'播放量',
		hotlist:'人气推荐',
		plsrefisrt:'请充值后看视频！',
	},
	choose:{
		title:'最好的一夜',
		nav:'选妃',
		apply:'匹配申请',
		jianjie:'美女简介',
		move:'是否可移动',
		click:'点击认证',
		detail:'详细信息',
		videodetail:'实时视频',
	},
	common:{
		refresh:'刷新成功',
		noallow:'功能已禁用！',
		disable:'禁用',
		all:'全部',
		nomore:'没有更多了。',
		nodata:'无数据',
		submit:'提交',
		more:'查看更多',
		pinglunnav:'评论',
		pingluntitle:'评论',
		pldtitle:'评论详情',
		minenav:'账户',
		homenav:'主页',
		addbank:'添加收款卡',
		setbank:'提款账户',
	},
	game:{
		expect:'期号',
		jieguo:'数据',
		da:'大',
		xiao:'小',
		dan:'单',
		shuang:'双',
		大:'大',
		小:'小',
		单:'单',
		双:'双',
		order:'任务单',
		clear:'清空订单',
		submit:'确认提交',
		kjcc:'开奖成功，期号:',
		lxgly:'请与您的管理员联系.',
		plsmoney:'请填写金额!',
		plsnum:'请选择号码!',
		moneynote:'余额不足，请联系客服充值!',
		lefttime:'剩余时间',
		lunci:'期号',
	},
	kefu:{
		title:'在线客服',
		lianxi:'联系',
		content:'全天7 * 24小时竭诚为您服务',
	},
	setname:{
		title:'修改实名',
		xingming:'名字',
		tips:'为了您的账户安全，实际名称必须与绑定银行卡名称匹配',
		xmpls:'请输入实际名称.',
		save:'保存',
		notreset:'不要重复设置！',
		plsentername:'请输入名称！',
	},
  
};
