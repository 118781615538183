<template>
<div>
	<div class="van-nav-bar van-hairline--bottom">
		<div class="van-nav-bar__content">
			<div class="van-nav-bar__left van-haptics-feedback" @click="back()">
				<i class="van-badge__wrapper van-icon van-icon-arrow-left van-nav-bar__arrow"></i>
			</div>
			<div class="van-nav-bar__title van-ellipsis">
				미션결과
			</div>
			</div>
	</div>
	<div class="top">
		<h1>
			{{name}}
		</h1>
		<span>
			일{{expect}}마침표
		</span>
	</div>
	<div class="status">
		<div class="left">
			<h1>
				{{money}}점
			</h1>
			<span>
				작업 포인트
			</span>
		</div>
		<div class="right">
			<h1 style="color: green;" v-if="status==1">
				선물이 완료되었습니다.
			</h1>
			<h1 style="color: red;" v-else>
				결과 대기중
			</h1>
		</div>
	</div>
	<div class="ls">
		<p>
			주문 내용:
			<span>
				{{touzhu}}
			</span>
		</p>
		<p>
			주문시간:
			<span>
				{{time}}
			</span>
		</p>
	</div>
	<div class="ls">
		<div class="title">
			세부
		</div>
		<table class="tb" border="0" cellpadding="0" cellspacing="0"
		style="width: 100%;">
			<tr>
				<td>
					콘텐츠
				</td>
				<td>
					금액
				</td>
				<td>
					결과
				</td>
				<td>
					시간
				</td>
			</tr>
			<tr v-for="(v,key) in list" :key="key" >
				<td>
					{{$t('game.'+v.type)}}
				</td>
				<td>
					{{v.money}}
				</td>
				<td style="color: rgb(54, 201, 137);" v-if="v.is_win==1">
					승리
				</td>
				<td style="color: rgb(245, 105, 105);" v-else-if="v.is_win==2">
					미션 실패
				</td>
				<td style="color: rgb(245, 105, 105);" v-else>
					결과 대기중
				</td>
				<td>
					{{v.update_time}}
				</td>
			</tr>
			
		</table>
	</div>
</div>
</template>

<script>
export default {
  data() {
    return {
      list:[],
	  name:'',
	  expect:'',
	  lid:'',
	  money:'',
	  touzhu:'',
	  time:'',
	  status:'',
    };
  },
  methods: {
    back(){
      this.$router.push({path:'GameRecord'})
    },
    getVideoInfo(){
      this.$http({
        method: 'get',
        data:{lid:this.$route.query.lid,expect:this.$route.query.expect},
        url: 'user_get_game_detail'
      }).then(res=>{
		  console.log(res.data)
        this.list = res.data;
      })

    },

  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
		this.name = this.$route.query.name;
		this.expect = this.$route.query.expect;
		this.money = this.$route.query.money;
		this.lid = this.$route.query.lid;
		this.touzhu = this.$route.query.touzhu;
		this.time = this.$route.query.time;
		this.status = this.$route.query.status;
      this.getVideoInfo();
    }
  }
};
</script>

<style>
:export {
    gbcolor: linear-gradient(30deg,#7d76ef,#d63f8c)
}

.top {
    width: 90%;
    margin: 0 auto;
    padding: 1rem 0;
    border-bottom: 1px solid #f2f2f5
}

.top h1 {
    font-size: 1.13333rem;
    padding: 0 0 .33333rem
}

.top span {
    font-size: .8rem;
    color: #7d7c7c;
    padding: .33333rem 0 0
}

.tb td {
    border: 1px solid #eee;
    text-align: center;
    padding: .46667rem 0;
    font-size: .76667rem;
    color: #333
}

.ls {
    width: 100%;
    border-top: .33333rem solid #f2f2f5;
    padding: 5%;
    text-align: left
}

.ls .title {
    font-size: 1rem;
    color: #000;
    font-weight: 600;
    padding-bottom: 1.33333rem
}

.ls>p {
    padding: .5rem 0;
    font-size: .76667rem;
    color: #000
}

.ls>p span {
    font-size: .93333rem
}

.btn a {
    width: 90%;
    display: flex;
    background: linear-gradient(90deg,#f560cd,#4f2b8a);
    color: #fff;
    justify-content: center;
    align-items: center;
    margin: 1.33333rem auto;
    padding: .66667rem 0;
    border-radius: 3.33333rem
}

.status {
    width: 90%;
    margin: 0 auto;
    display: flex;
    height: 6.66667rem;
    justify-content: space-between;
    align-items: center;
    position: relative
}

.status:before {
    content: "";
    width: 1px;
    height: 30%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #f2f2f5
}

.status>div {
    width: 50%
}

.status>div h1 {
    font-size: 1.13333rem
}

.status>div span {
    font-size: .86667rem;
    color: #979799;
    padding-top: .66667rem;
    display: inline-block
}

</style>