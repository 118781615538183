<template>
<div class="message">
	<div class="van-nav-bar van-hairline--bottom routes">
		<div class="van-nav-bar__content">
			<div class="van-nav-bar__left van-haptics-feedback" @click="back()">
				<i class="van-badge__wrapper van-icon van-icon-arrow-left van-nav-bar__arrow"></i>
				</div>
			<div class="van-nav-bar__title van-ellipsis">
				시스템 공지
			</div>
			</div>
	</div>
	<div class="van-cell-group van-hairline--top-bottom">
		<div class="van-cell">			
			<div class="van-cell__title">
				<p>
					{{notice.name}}
				</p>
				<span>
					<div style="white-space: pre-wrap;" v-html="notice.text"></div>
				</span>
			</div>			
		</div>
		
	</div>
	<van-dialog v-model="show24" :title="dtitle" >
		{{dcontent}}	  
	</van-dialog>
</div>

</template>

<script>

export default {
  data() {
    return {
      isLoading: false,
      notice:{},
	  show24:false,
	  dtitle:'',
	  dcontent:'',
      loading: false,
      finished: false,
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    getNoticeList(){
      this.$http({
        method: 'get',
        url: 'sys_get_notice_list'
      }).then(res=>{
        console.log(res);
        this.notice = res.data[0]
      })
    },
	showD(title,content){
		this.dtitle = title;
		this.dcontent = content;
		this.show24 = true;
	},
    onRefresh() {
      setTimeout(() => {
        this.$toast(this.$t('common.refresh'));
        this.isLoading = false;
        this.getNoticeList();
      }, 500);
    },
  },
  created() {
    this.getNoticeList();
	
  }
};
</script>

<style lang='less' scoped>
:export {
    gbcolor: linear-gradient(30deg,#7d76ef,#d63f8c)
}
.van-nav-bar {
    z-index: 999;
	position: relative;
    background: url(/img/header.svg) no-repeat 50%/100%;
}
.van-nav-bar__content{
	height:3rem;
}
.routes {
    background: linear-gradient(20deg,#a253d0,#d63f8c);
}
.message {
    width: 100%;
    height: 100%;
    background: #f0f0f0
}

.message .van-cell {
    width: 94%;
    margin: 0.6rem auto 0.1rem;
	padding: 0.5rem;
    border-radius: 4px
}

.message .van-cell:before {
    display: none
}

.message .van-cell__title p {
    font-size: 1rem;
	padding: 0.3rem 0rem;
    font-weight: 600
}

.message .van-cell__title span {
    font-size: 1rem;
    color: #9a8fbf
}

.message .van-cell__value {
    display: flex;
    justify-content: flex-end;
    align-items: center
}

.message .van-cell__value a {
    padding: 0.4rem 0.8rem;
    font-size: 1rem;
    border-radius: 2px;
    color: #fff;
    background: linear-gradient(30deg,#8175eb,#de55a1)
}

</style>
