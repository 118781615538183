<template>
<div class="bind">
	<div class="van-nav-bar van-hairline--bottom">
		<div class="van-nav-bar__content">
			<div class="van-nav-bar__left van-haptics-feedback"  @click="back()">
				<i class="van-badge__wrapper van-icon van-icon-arrow-left van-nav-bar__arrow"></i>
			</div>
			<div class="van-nav-bar__title van-ellipsis">
				{{$t('common.setbank')}}
			</div>
		</div>
	</div>
	<div class="add-card" @click="toBindCard()" v-if="!is_bind">
		<van-icon name="plus" size="1.2rem" />
		<span>{{$t('common.addbank')}}</span>
	</div>
	<div class="bank" v-else>
		<p>
			{{this.bankInfo.bankinfo}}
		</p>
		<h1>
			{{this.bankInfo.bankid}}
		</h1>
		<span>
			{{this.bankInfo.bankname}}
		</span>
	</div>
</div>

</template>

<script>
export default {
  data() {
    return {
      is_bind:false,
      bankInfo:{},
      userInfo:{}
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    getUserBankInfo(){
      this.$http({
        method: 'get',
        url: 'user_get_bank'
      }).then(res=>{
        if(res.code === 200){
          if(res.data.is_bank){
            this.is_bind = true;
            this.bankInfo = res.data.info;
          }else {
            this.is_bind = false;
          }
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
          this.userInfo = res.data;
          this.name = res.data.name;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    toBindCard() {
      if (!this.userInfo.name) {
        this.$router.push("Setname");
        this.$toast("성함 설정 후 출금계좌 연동부탁드립니다！");
        return true;
      }else if(!this.userInfo.paypassword){
        this.$router.push("SetPayPassword");
        this.$toast("현금인출 비밀번호를 설정한 후에 은행카드를 묶으세요!");
        return true;
      } else {
        this.$router.push({path:'/BindCard'})
      }
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserInfo();
      this.getUserBankInfo();
    }
  }
};
</script>

<style lang='less' scoped>
:export {
    gbcolor: linear-gradient(30deg,#7d76ef,#d63f8c)
}
.van-nav-bar {
    z-index: 999;
	position: relative;
    background: url(/img/header.svg) no-repeat 50%/100%;
}
.van-nav-bar__content{
	height:3rem;
}
.bind {
    width: 100%;
    height: 100%;
    background: #f0f0f0
}

.bind .van-field__control::-webkit-input-placeholder {
    text-overflow: ellipsis;
    white-space: normal;
    overflow: hidden
}

.bind .van-field__control::-moz-placeholder {
    text-overflow: ellipsis;
    white-space: normal;
    overflow: hidden
}

.bind .van-field__control:-ms-input-placeholder {
    text-overflow: ellipsis;
    white-space: normal;
    overflow: hidden
}

.bind .btn {
    width: 100%;
    padding: 10%;
    display: flex;
    justify-content: center;
    align-items: center
}

.bind .btn a {
    width: 100%;
    background: linear-gradient(90deg,#f560cd,#4f2b8a);
    color: #fff;
    padding: .66667rem 0;
    border-radius: .33333rem
}

.bind .bank {
    width: 90%;
    height: 10rem;
    background: #705b65;
    border-radius: .66667rem;
    margin: 1rem auto;
    text-align: left;
    padding: 5%;
    position: relative;
    overflow: hidden
}
.bind .add-card{
	width: 90%;
	height: 10rem;
	background: #705b65;
	border-radius: .66667rem;
	text-align: center;
	padding: 14%;
	position: relative;
	margin: 1rem auto;
	font-size: 1.5rem;
	color: #fff;
}

.bind .bank p {
    color: #ede7e7;
    padding: .13333rem 0;
    position: relative;
    z-index: 9
}

.bind .bank h1 {
    color: #fff;
    padding: .33333rem 0;
    position: relative;
    z-index: 9
}

.bind .bank span {
    position: absolute;
    right: .66667rem;
    bottom: .66667rem;
    color: #dfbb9e;
    z-index: 9
}

.bind .bank i {
    position: absolute;
    right: -3.33333rem;
    top: 0;
    color: #877f7e;
    font-size: 10rem;
    opacity: .4
}

.bind .van-cell__value {
    justify-content: space-between;
    align-items: center;
    display: flex
}

.bind .van-cell__value .van-field__body {
    width: 100%
}

.bind .van-cell__value .van-dropdown-menu__bar {
    height: auto;
    box-shadow: none
}

.bind .van-cell__value .van-dropdown-menu__bar .van-dropdown-menu__item {
    justify-content: flex-start
}

.bind .van-cell__value .van-dropdown-menu__title {
    padding-left: 0
}

</style>
