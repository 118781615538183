<template>
  <div class="movie">
    <van-nav-bar title="" left-text="" @click-left="toService"  left-arrow>
      <template #title>
        {{$t('video.title')}}
      </template>
      <template #left>
        <van-icon name="chat-o" size="26"  />
      </template>
    </van-nav-bar>
    <van-tabs style="height: 44px;" v-model="active"  :swipe-threshold="4"  @change="OnChange">
      <van-tab v-for="(v,key) in videolitem" :key="key" :title="v.name" :name="v.key" style="font-size: 14px;" ></van-tab>
    </van-tabs>
    
        <div class="movie-list-tab">
          <van-pull-refresh v-model="isLoading"  class="fixedStyle"  @refresh="onRefresh" @scroll="handleScroll">
			  <van-list
			      v-model="loading"
			      :finished="finished"
			      :immediate-check="false"
			      :finished-text="$t('common.nomore')"
			      @load="onLoad"
			  >
			    <ul class="hot" style="padding-top: 0.5rem;">
			    	<li v-for="(v,key) in videolist" :key="key" @click="toPlayVideo(v.id)">
			    		<img :src="v.vod_pic"
			    		alt="">
			    		<div>
			    			<span>
			    				{{v.vod_name}}
			    			</span>
			    			<span>
			    				{{$t('video.nums')}}:{{v.count}}
			    			</span>
			    		</div>
			    	</li>
			    			
			    </ul>
			  </van-list>			
          </van-pull-refresh>
        </div>
      
  </div>
</template>

<script>
import { Toast } from 'vant';
export default {
  data() {
    return {
      active: 0,
      isLoading: false,
      count:0,
      loading: false,
      finished: false,
      refreshing: false,
      videolitem: [],
      videolist: [],
      number:0,
      page:1,
      videoSwiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 0,
        slidesPerGroup : 1,
      }
    };
  },
  methods: {
	  handleScroll(e) {
	  	const { scrollTop, scrollHeight, clientHeight } = e.target;
	  	if (scrollTop + clientHeight >= scrollHeight - 5 && this.videolist.length < this.count) { // 5是一个缓冲值，避免提前加载
	  		this.loading = true;
	  		setTimeout(() => { // 模拟异步加载数据
	  			this.getVideoList();
	  		}, 1000);
	  	}
	  },
    getVideoClass(){
      this.$http({
        method: 'get',
        url: 'video_class'
      }).then(res=>{
        this.videolitem = res.data;
      })
    },
	toService(){
	  if(this.$store.getters.getBaseInfo.iskefu == 1){
	    this.$router.push("ServiceOnline");
	  }else {
	    this.$toast.fail(this.$t('common.noallow'));
	  }
	},
	longPress() {
	  document.querySelector('.fixedStyle').scrollTop = 0
	},
	scrollTops(){
	  this.scrollTop1 = document.querySelector('.fixedStyle').scrollTop
	},
    toPlayVideo(id){
      if(!localStorage.getItem('token')){
        this.$router.push({path:'/Login'})
      }else {
        this.$router.push({path:'/PlayVideo?id='+id})
      }

    },
    itemChange(){
      this.active = this.$refs.swiper.swiper.activeIndex
      this.OnChange()
    },
    getVideoList(){
      this.$http({
        method: 'get',
        data:{id:this.active,page:this.page},
        url: 'video_list'
      }).then(res=>{
		  console.log(res)
        this.videolist = this.videolist.concat(res.data.data);
        this.count = res.data.count;
        this.page++;

      })
    },
    onLoad() {
        this.getVideoList();
      let timer = setTimeout(() => {
        if (this.refreshing) {
          this.videolist = [];
          this.refreshing = false;
        }
        this.loading = false;
        if (this.videolist.length === this.count) {
          this.finished = true;
        }
        this.finished && clearTimeout(timer);//清除计时器
      }, 500);
    },
     OnChange(){
      this.videolist = [];
      this.number = 0;
      this.page = 1;
      this.count = 0;
      this.getVideoList();//获取视频列表

    },
    onRefresh() {
      setTimeout(() => {
        this.finished = false;
        this.loading = true;
        // this.onLoad();
        this.isLoading = false;
        Toast(this.$t('common.refresh'));
      }, 500);
    },
  },
  created() {
    this.getVideoClass();//获取视频类目
    this.OnChange()
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/basenew.css";


:export {
    gbcolor: linear-gradient(30deg,#7d76ef,#d63f8c)
}
.fixedStyle{
	height: 100vh;
	overflow-y: scroll;
	position: relative;
	margin-top:0.7rem;
	padding: 0 0.8rem 0.2rem;
}
.movie {
    width: 100%;
    min-height: 100%;
    background: #fff
}
.van-nav-bar {
	position: relative;
    z-index: 999;
	height:3rem ;
	line-height: 3rem;
    background: url(/img/header.svg) no-repeat 50%/100%
}
.movie .van-hairline--bottom:after {
    border-bottom-width: 0
}

.movie .van-tab__panel-wrapper,.movie .van-tab__panel {
    padding: 0 10px 68px;
    background: #f2f2f5
}

.movie .movietle img {
    position: absolute;
    right: -.73333rem;
    top: .8rem;
    width: 1.46667rem
}

.movie .movietle .hot {
    width: .86667rem;
    right: -.2rem;
    top: .66667rem
}

.movie .van-tab {
    padding: 0 0.5rem;
}
.movie .van-tab {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
}


.hot {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap
}

.hot li {
    width: calc(50% - .33333rem);
    border-radius: .33333rem;
    overflow: hidden;
    margin-top: .66667rem;
    height: 6.66667rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center
}

.hot li img {
	width: 100%;
    height: 100%
}

.hot li>div {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,.4);
    padding: .33333rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center
}

.hot li>div span {
    color: #fff;
    font-size: .8rem;
    width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 .33333rem
}

:export {
    gbcolor: linear-gradient(30deg,#7d76ef,#d63f8c)
}

.movie {
    width: 100%;
    min-height: 100%;
    background: #fff
}

.movie .van-hairline--bottom:after {
    border-bottom-width: 0
}

.movie .van-tab__panel-wrapper,.movie .van-tab__panel {
    padding: 0 10px 68px;
    background: #f2f2f5
}

.movie .movietle img {
    position: absolute;
    right: -.73333rem;
    top: .8rem;
    width: 1.46667rem
}

.movie .movietle .hot {
    width: .86667rem;
    right: -.2rem;
    top: .66667rem
}


::v-deep .van-tabs__nav {
  background: #fff;
}
::v-deep .van-tab {
  color: #442889;
  font-size: 1rem;
}
::v-deep .van-tab--active {
    font-size: 1.1rem;
    background-image: linear-gradient(90deg,#8e4ff1,#c571cf);
    -webkit-background-clip: text;
    color: #c571cf
}
::v-deep .van-tabs__line {
  bottom: 0.6rem;
  width: 1.5rem;
  height: 0.2rem;
  border-radius: 0px;
  background-color: #ffffff;
}
::v-deep .van-tabs--line .van-tabs__wrap {
  height: 3.5rem;
}
::v-deep .van-tabs__wrap--scrollable .van-tab {
  padding: 0 0.5rem;
}
::v-deep  .van-hairline--bottom::after {
  border-bottom-width: 0px;
}
.video_swiper {
  width: 100%;
  margin-top: 0.6rem;
  flex: 1;
  .swiper-slide {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 100%;
    justify-content: center;
    height: 100%;
    position: relative;
    transition-property: transform;
  }
}
.movie-list-tab {
  overflow: auto;
  height: 100%;
}
::v-deep .van-pull-refresh__track .van-pull-refresh__head *{
  color: #000;
  font-size: 35px;
}
.movie-list-tab .hot-recommend-div{
  height: 100%;
  margin: 1rem auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  //overflow: auto;
}
.list-item{
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin: 0 auto;
  align-items: flex-start;
  /* justify-content: flex-start; */
  flex-wrap: wrap;
}
.list-item .movie-list-item:nth-child(odd) {
  margin-right: 0.1rem;
}

.movie-list-item .cover_img{
  width: 12rem;
  border-radius: .33333rem;
  height: 7.1rem;
}
.movie-list-item{
  margin-bottom: -10px;
}
.list-item .movie-list-item-bottom{
  position: relative;
  width: 100%;
  bottom: 42px;
}
.list-item .movie-list-item-bottom .movie-time-div{
  background-color: rgba(0,0,0,.4);
}
.list-item .movie-list-item-bottom>div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.list-item .movie-list-item-bottom .movie-time-div .van-count-down {
  color: #fff;
}
.list-item .movie-list-item .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 180px;
  padding-left: 8px;
  font-size: 25px;
}
.list-item .movie-time-div {
  color: #fff;
  border-radius: 0 0 20px 20px;
  height: 35px;
}

</style>
